import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong parentName="p">{`Projects`}</strong></p>
    <a href="https://tweak-extension.com" title="tweak your HTTP requests to test, develop and demo your web application" target="_blank" id="prj-1">
  tweak browser extension
    </a>
    <span> · </span>
    <a href="https://www.github.com/danielcaldas/react-d3-graph" title="Interactive and configurable graphs with react and d3 effortlessly" target="_blank" id="prj-0">
  react-d3-graph
    </a>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      